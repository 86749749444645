import React, { useRef } from 'react';
import { FeatureGroup, Popup, Tooltip, Circle } from 'react-leaflet';

export default function LayerFtNodiLepida({ ft_sedi }) {
    const groupRef = useRef();

    const renderNodiReteOnMap = () => {
        return ft_sedi.features.flatMap((sede, index) => {
                const { properties: { nodi_lepida } } = sede;
                return nodi_lepida.features.map((lepida_node, index2) => {
                        const { coordinates } = lepida_node.geometry;
                        return <Circle
                            key={`${index}${index2}`}
                            center={[coordinates[1], coordinates[0]]}
                            radius={20}
                            pathOptions={{ color: 'red', fillColor: 'blue' }} >
                            <Tooltip>
                                {lepida_node.properties.etichetta}
                            </Tooltip> </Circle>
                    })
                })
    }

    return (
        <FeatureGroup color="purple" ref={ groupRef } >
            { renderNodiReteOnMap() }
        </FeatureGroup>
    );
}
