import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '500px'
  }
}));

export default function Accessibility() {
  const classes = useStyles();

  return (
    <div>
    <div>
        <h1 class="mb-3">Dichiarazione di accessibilità</h1>

        <p>Questo sito è stato progettato e realizzato seguendo i principi di accessibilità previsti nella 
            <a href="https://www.agid.gov.it/it/design-servizi/accessibilita">
                Legge 9 gennaio 2004, n. 4</a> (comunemente chiamata "Legge Stanca", G.U. 17 gennaio 2004),
            recante «Disposizioni per favorire l'accesso dei soggetti disabili agli strumenti informatici».</p>

        <p>I requisiti di accessibilità di questo sito sono oggetto di costante verifica - sul piano tecnico e su
            quello dell'usabilità - nel rispetto del <a href="https://www.agid.gov.it/it/decreto-legislativo-10-agosto-2018-n-106">
                D.lgs 10 agosto 2018, n. 106</a> che ha recepito la <a href="https://www.agid.gov.it/sites/default/files/repository_files/direttiva_ue_2016-2102.pdf">
                direttiva UE 2016/2102</a> del Parlamento europeo e del Consiglio richiedendo l'allineamento dei siti Internet
            delle PA al livello 2.1 delle <a href="http://www.w3.org/TR/WCAG20/">Web Content Accessibility Guidelines</a>
            (WCAG) del W3C (allegato A alla norma UNI EN 301549:2018).
        </p>

        <p>Il portale è stato progettato e testato per garantire la massima compatibilità con i principali sistemi operativi e browser, sia dal punto di vista funzionale che di visualizzazione.</p>

        <p>Nella realizzazione del sito si è privilegiato l'utilizzo di HTML5 e CSS3 per sfruttare al meglio le caratteristiche
            dei browser e dei dispositivi mobile attuali e futuri grazie al supporto di funzionalità avanzate e dinamiche;
            pertanto, in alcuni casi la validazione W3C della sintassi HTML e CSS può risultare non pienamente conforme.
            I colori utilizzati consentono un corretto contrasto tra i caratteri in primo piano e lo sfondo, secondo quanto
            indicato dai requisiti WCAG AA 2.1.</p>

        <p>Il testo è ridimensionabile tramite le opzioni del browser senza l'ausilio di tecnologie assistive e senza
            perdita di contenuto e funzionalità: tuttavia, con l'uso di browser particolarmente datati sono possibili casi di
            sovrapposizione di elementi, che non precludono in ogni caso l'accesso alle informazioni e alla navigazione.</p>

        <p>Ogni modello di pagina è stato sottoposto a verifiche attraverso l'utilizzo di strumenti automatici;
            nello specifico, sono stati impiegati:</p>
        <ul>
            <li>per la validazione sintassi HTML - <a href="https://validator.w3.org/nu">W3C Markup NU HTML Validator</a></li>
            <li>per la validazione sintassi CSS - <a href="https://jigsaw.w3.org/css-validator/">W3C CSS Validation Service</a></li>
            <li>per la valutazione di accessibilità - <a href="https://wave.webaim.org/">WAVE</a> e <a href="https://mauve.isti.cnr.it/index.jsp">MAUVE++</a></li>
            <li>per la verifica puntuale del livello di contrasto tra i colori - <a href="https://webaim.org/resources/contrastchecker/">WebAIM Contrast Checker</a></li>
        </ul>
        <p>Nonostante l'attenzione posta nelle fasi di progettazione e di realizzazione del sito e le continue verifiche e
            adeguamenti apportati per raggiungere un pieno allineamento a quanto stabilito dalle <a href="https://www.agid.gov.it/it/design-servizi/accessibilita">Linee Guida sull'accessibilità
                degli strumenti informatici</a> emesse da AGID - Agenzia per l'Italia digitale, non è possibile escludere con certezza
            che una o più pagine possano tuttora risultare non pienamente accessibili per alcune categorie di utenti.</p>

        <p>Qualora dovesse verificarsi uno di questi casi, invitiamo gli utenti ad utilizzare il modulo online <a href="https://lepida.net/accessibilita/feedback-accessibilita">
            Feedback accessibilità</a> per segnalare ogni eventuale non conformità, al fine di consentirne l'eliminazione nel più
            breve tempo possibile.</p>

        <p><b>Dichiarazione di accessibilità</b></p>
        <p>Consulta la dichiarazione di accessibilità per questo sito.</p>

        <p><b>Obiettivi di accessibilità</b>
            In attuazione dell'art. 9, comma 7, del decreto legge n. 179 del 18/10/2012, e secondo le indicazioni della circolare AgID n. 61/2013, entro il 31 marzo di
            ogni anno le amministrazioni pubbliche devono pubblicare sul proprio sito web gli obiettivi annuali di accessibilità.

        </p><p>Consulta <a href="https://www.lepida.net/societa-trasparente/altri-contenuti/accessibilita-catalogo-dei-dati-metadati-banche-dati">gli
            obiettivi di accessibilità</a> di Lepida ScpA.</p>
    </div>
</div>

  );
}