import React, { useRef } from 'react';
import { useMap, FeatureGroup, Tooltip, Marker } from 'react-leaflet';
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";

import NonValidato from './allert-location.png';
import Validato from './location-correct.png';

export default function LayerFtSedi({ ft_sedi }) {
  const groupRef = useRef();

  const map = useMap();

  React.useEffect(() => {
    if (ft_sedi) {
      const bounds = groupRef.current.getBounds()
      if (bounds.isValid()) {
        map.fitBounds(bounds, { padding: [100, 100] });
      }
    }
  }, [ft_sedi]);

  const nonValidatoIconMarkup = renderToStaticMarkup(
    <img alt='Non Validato' src={NonValidato} style={{ height: 35 }} />
  );
  const nonValidatoMarkerIcon = divIcon({
    html: nonValidatoIconMarkup,
    iconSize: [28, 38],
    iconAnchor: [14, 38],
    popupAnchor: [-2, -36],
    tooltipAnchor: [-2, -36]
  });

  const validatoIconMarkup = renderToStaticMarkup(
    <img alt='Validato' src={Validato} style={{ height: 35 }} />
  );
  const validatoMarkerIcon = divIcon({
    html: validatoIconMarkup,
    iconSize: [28, 38],
    iconAnchor: [14, 38],
    popupAnchor: [-2, -36],
    tooltipAnchor: [-2, -36]
  });


  const renderNodiReteOnMap = () => {
    return ft_sedi.features.map((lepida_node, index2) => {
      const { coordinates } = lepida_node.geometry;
      return <Marker
        key={`${index2}`}
        position={[coordinates[1], coordinates[0]]}
        icon={lepida_node.properties.validato ? validatoMarkerIcon : nonValidatoMarkerIcon}
        onClick={e => {
          console.log(e.target);
        }}
      >
        <Tooltip>
          {lepida_node.properties.denominazione} <br/> 
          {lepida_node.properties.indirizzo} <br/> 
          <b style={{ background: lepida_node.properties.validato ? '#66FF00' : 'yellow', color: 'black', fontSize: '10px' }}>
            {lepida_node.properties.validato ? 'SEDE VALIDATA' : 'SEDE NON VALIDATA'}</b>

          {lepida_node.properties.validato && <>
          <p>Schema Attivazione: <b>{lepida_node.properties.sch_attivazione}</b></p>
          <p>Schema di infrastrutturazione: <b>{lepida_node.properties.sch_infrastrutturazione}</b></p>
          <p>Inizio infrastrutturazione: <b>{lepida_node.properties.start_infrastrutturazione} / {lepida_node.properties.etichetta_start_infrastrutturazione}</b></p>
          <p>POP Attivazione: <b>{lepida_node.properties.pop_attivazione} / {lepida_node.properties.etichetta_pop_attivazione}</b></p>
          </> }
        </Tooltip>
      </Marker>
    })
  }

  return (
    <FeatureGroup ref={groupRef} >
      {renderNodiReteOnMap()}
    </FeatureGroup>
  );
}
