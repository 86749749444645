import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import Tooltip from '@material-ui/core/Tooltip';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


const useStyles = makeStyles((theme) => ({
    totaleRow: {
        backgroundColor: theme.palette.action.hover
    }
}));


export default function DettaglioFt({ sedi, percentualeScavo, setPercentualeScavo }) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const COSTO_SCAVO = parseFloat(process.env.REACT_APP_COSTO_SCAVO_METRO);

    const handleSliderChange = (event, newValue) => {
        setPercentualeScavo(newValue);
    };

    function getLunghezzaTracciati(tipo) {
        return sedi.features.map((sede) =>
            sede.properties.lunghezza_link
                .filter(ln => ln.tipo === tipo)
                .reduce((a, b) => a + parseFloat(b.lunghezza_link), 0).toFixed(2)
        ).reduce((a, b) => a + parseFloat(b), 0).toFixed(2)
    }

    function getLunghezzaTotale() {
        return [getLunghezzaTracciati(2), getLunghezzaTracciati(3), getLunghezzaTracciati(1)].reduce((a, b) => a + parseFloat(b), 0).toFixed(2)
    }

    function getPrezzoScavo() {
        return getLunghezzaTracciati(2) * COSTO_SCAVO
    }

    function getPrezzoSoloPosa() {
        return getLunghezzaTracciati(3) * 8
    }

    function getPrezzoNonDefinito() {
        const scavo = percentualeScavo / 100
        const posa = 1 - scavo
        return getLunghezzaTracciati(1) * (8 * posa + COSTO_SCAVO * scavo)
    }

    function getPrezzoSedi() {
        return (3000 * sedi.features.length)
    }

    function getPrezzoTotale() {
        return getPrezzoScavo() + getPrezzoSoloPosa() + getPrezzoNonDefinito() + getPrezzoSedi()
    }

    return (
        <TableContainer>
            <Table aria-label="spanning table">
                <TableBody>

                    <TableRow className={classes.totaleRow}>
                        <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Typography variant="subtitle2" gutterBottom>
                                Totale
                            </Typography>
                        </TableCell>
                        <TableCell align="right">{`${getLunghezzaTotale()} metri`}</TableCell>
                        <TableCell align="right">
                            <Typography variant="subtitle2" gutterBottom>
                                {getPrezzoTotale().toFixed(2)} €
                            </Typography></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box margin={1}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        Dettaglio Sedi
                                    </Typography>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#Numero Sedi</TableCell>
                                                <TableCell align="right">Costo per sede</TableCell>
                                                <TableCell align="left" aria-label='Info'></TableCell>
                                                <TableCell align="right">Prezzo (€)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>{sedi.features.length}</TableCell>
                                                <TableCell align="right">3000€/sede</TableCell>
                                                <TableCell align="left">
                                                    <Tooltip title="Quotazione forfettaria delle opere necessarie a rendere fruibile il collegamento di ciascuna sede:  allestimento sede, terminazione circuiti, etc.">
                                                        <InfoIcon size="small" color="primary"/>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right">{getPrezzoSedi().toFixed(2)} €</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                                <Box margin={1}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        Dettaglio Tracciati
                                    </Typography>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Tipo tracciato</TableCell>
                                                <TableCell align="right" aria-label='Impostazione range scavo'></TableCell>
                                                <TableCell align="right">Lunghezza</TableCell>
                                                <TableCell align="right">Costo per metro</TableCell>
                                                <TableCell align="left" aria-label="Info"></TableCell>
                                                <TableCell align="right">Prezzo (€)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ textDecoration: 'underline orange double' }}>NON DEFINITO</TableCell>
                                                <TableCell>
                                                    <Typography id="discrete-slider" gutterBottom>
                                                        Imposta percentuale scavo tracciato
                                                    </Typography>
                                                    <Slider
                                                        defaultValue={percentualeScavo}
                                                        onChange={handleSliderChange}
                                                        aria-labelledby="discrete-slider"
                                                        valueLabelDisplay="auto"
                                                        step={10}
                                                        marks
                                                        min={0}
                                                        max={100}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">{`${getLunghezzaTracciati(1)} m`}</TableCell>

                                                <TableCell align="right">Calcolato in base alla percentuale di tracciato in posa o di scavo.</TableCell>
                                                <TableCell align="left">
                                                </TableCell>
                                                <TableCell align="right">{`${getPrezzoNonDefinito().toFixed(2)}€`}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ textDecoration: 'underline red double' }}>SCAVO</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right">{`${getLunghezzaTracciati(2)} m`}</TableCell>

                                                <TableCell align="right">{COSTO_SCAVO}€/m</TableCell>
                                                <TableCell align="left">
                                                    <Tooltip title="Valore medio di infrastrutturazione ex novo con tecnica di no-dig leggero e accessori legati alla posa di minicavi, ripristino buche e allestimento pozzetti (ref. Rdo 2020_08).">
                                                        <InfoIcon size="small" color="primary"/>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right">{`${getPrezzoScavo().toFixed(2)}€`}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ textDecoration: 'underline green double' }}>SOLO POSA</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="right">{`${getLunghezzaTracciati(3)} m`}</TableCell>

                                                <TableCell align="right">8€/m</TableCell>
                                                <TableCell align="left">
                                                    <Tooltip title="Valore medio di opere di sottotubazione mediante minitubi e posa minicavi (ref. Rdo 2020_08)">
                                                        <InfoIcon size="small" color="primary"/>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right">{`${getPrezzoSoloPosa().toFixed(2)}€`}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>


                </TableBody>
            </Table>
        </TableContainer >
    );
}